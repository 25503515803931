<template>
    <v-card :height="height">
        <l-map ref="map" :zoom="zoom" :center="center" style="z-index: 1 !important;">
            <v-tilelayer-googlemutant lang="es-CL" :options="{ type: 'hybrid' }" />
            <!--v-tilelayer-googlemutant apikey="YOUR_API_KEY" lang="es-CL" :options="{ type: 'hybrid' }" /-->
        </l-map>
    </v-card>
</template>

<script>
import { LMap, LMarker, LTileLayer } from 'vue2-leaflet'
import LDraw from 'leaflet-draw';
import 'leaflet/dist/leaflet.css';
import 'leaflet-draw/dist/leaflet.draw.css';
import Vue2LeafletGoogleMutant from 'vue2-leaflet-googlemutant';

export default {
    props: ['value', 'height', 'editable'],
    components:{
        LMap,
        LMarker,
        LTileLayer,
        'v-tilelayer-googlemutant': Vue2LeafletGoogleMutant
    },
    data: () => ({
        zoom:18,
        center: L.latLng(-31.8226285,-70.5819457),
        editableLayers: null
    }),
    computed: {
        result() {
            return this.editableLayers._layers;
        }
    },
    mounted() {
        this.$nextTick(() => {

            this.editableLayers = new window.L.FeatureGroup();
            const map = this.$refs.map.mapObject;
            const drawControl = new window.L.Control.Draw({
                position: 'topleft',
                draw: {
                    polyline: false,
                    polygon: false,
                    rectangle: false,
                    circle: false,
                    circlemarker: false,
                    marker: true
                },
                edit: {
                    featureGroup: this.editableLayers,
                }
            });
            map.addLayer(this.editableLayers);
            if(this.editable)
                map.addControl(drawControl);

            map.on(window.L.Draw.Event.CREATED, (e) => {
                // const type = e.layerType;
                const layer = e.layer;

                // Do whatever else you need to. (save to db, add to map etc)
                this.editableLayers.addLayer(layer);
            });
        });
    }
}
</script>