<template>
    <div>
        <v-row v-if="!$parent.$parent.get_permission('edit')">
            <v-col>
                <AccessMessage />
            </v-col>
        </v-row>
        <v-form id="editor" ref="editor" v-else>
            <v-row>
                <v-col cols="12" sm="6" md="4">
                    <DashCard title="General" title-icon="edit">
                        <v-row dense>
                            <v-col cols="12" lg="6">
                                <v-label>Fecha de Solicitud</v-label>
                                <DatePickerText flat hide-details dense v-model="sia.fecha_solicitud" />
                            </v-col>
                            <v-col cols="12" lg="6">
                                <v-label>Código SIA</v-label>
                                <v-text-field solo flat outlined dense hide-details v-model="sia.cod_externo" label="Código SIA"></v-text-field>
                            </v-col>
                            <v-col cols="12">
                                <v-label>Estado</v-label>
                                <v-select solo flat outlined dense hide-details prepend-inner-icon="flag" v-model="sia.estado" :rules="[rules.required]" :items="estados" item-text="text" :return-obejct="false" item-value="value"></v-select>
                            </v-col>
                        </v-row>
                    </DashCard>
                </v-col>
                <v-col cols="12" sm="6" md="8" lg="4">
                    <DashCard title="Responsables" title-icon="person_pin">
                        <EditorResponsable v-model="sia" :personas="personas" :rules="[rules.required]" />
                    </DashCard>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                    <DashCard title="Detalles del Cierre" title-icon="person_pin">
                        <v-row dense>     
                            <v-col cols="12" lg="6">
                                <v-label>Fecha de Cierre</v-label>
                                <DatePickerText flat dense v-model="sia.fecha_cierre" />
                            </v-col>
                            <v-col cols="12">
                                <v-label>Personal Responsable</v-label>
                                <v-autocomplete
                                    prepend-inner-icon="account_circle"
                                    :rules="[rules.required]" 
                                    autocomplete="off" 
                                    v-model="sia.responsable_cierre" 
                                    :items="personas"
                                    :item-text="(i) => i.first_name + ' ' + i.last_name" 
                                    return-object
                                    flat solo outlined dense />
                            </v-col>
                        </v-row>
                    </DashCard>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                    <DashCard title="Solicitante" title-icon="person">
                        <v-row dense>
                            <v-col cols="12">
                                <v-label>Empresa</v-label>
                                <Selector flat dense :rules="[rules.required]" v-model="sia.empresa" :items="empresas" return-object
                                        label="Empresa"
                                        create-dialog-title="Crear nueva empresa" 
                                        create-dialog-placeholder="Nombre de la empresa"
                                        item-text="nombre" />
                            </v-col>
                            <v-col cols="12">
                                <v-label>Nombre del Solicitante</v-label>
                                <v-text-field flat solo outlined dense hide-details v-model="sia.persona_empresa_solicitante" :rules="[rules.required]" />
                            </v-col>
                        </v-row>
                    </DashCard>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                    <DashCard title="Lugar" title-icon="place">
                        <v-row dense>
                            <v-col cols="12" md="12">
                                <v-label>Area Responsable</v-label>
                                <Selector dense 
                                        :rules="[rules.required]"
                                        v-model="sia.area_responsable" 
                                        :items="areas" 
                                        return-object
                                        label="Área"
                                        create-dialog-title="Crear nueva Área" 
                                        create-dialog-placeholder="Nombre del Área"
                                        item-text="nombre" />
                            </v-col>
                            <v-col cols="12" md="12">
                                <v-label>Gerencia Responsable</v-label>
                                <Selector dense 
                                        :rules="[rules.required]"
                                        v-model="sia.gerencia_responsable" 
                                        :items="gerencias" 
                                        return-object
                                        label="Gerencia"
                                        create-dialog-title="Crear nueva gerencia" 
                                        create-dialog-placeholder="Nombre de la Gerencia"
                                        item-text="nombre" />
                            </v-col>
                        </v-row>
                    </DashCard>
                </v-col>
                <v-col cols="12" lg="4">
                    <DashCard title="Detalles de la solicitud" title-icon="edit">
                        <v-row dense>
                            <v-col cols="12" md="12">
                                <v-label>Tipo de Obra</v-label>
                                <v-textarea flat solo outlined dense hide-details v-model="sia.descripcion_tipo_obra" :rules="[rules.required]" />
                            </v-col>
                            <v-col cols="12" md="12">
                                <v-label>Observaciones</v-label>
                                <v-textarea flat solo outlined dense hide-details v-model="sia.observacion" />
                            </v-col>
                        </v-row>
                    </DashCard>
                </v-col>
                <v-col cols="12">
                    <BoxGeoreferencia ref="geo" v-model="sia.georeferencia" :readonly="disable_geoeditor" />
                </v-col>
                <v-col cols="12">
                    <DashCard :actions="true" actions-align="right">
                        <template slot="actions">
                            <v-btn large depressed color="primary" @click="askSubir"
                                form="editor">
                                <span class="subtitle-2">Guardar cambios</span>
                            </v-btn>
                            <v-btn large text color="primary" @click="askCancelar">
                                <span class="subtitle-2">Cancelar</span>
                            </v-btn>
                        </template>
                    </DashCard>
                </v-col>
            </v-row>
        </v-form>
        
        <Message ref="success_dialog" @onClose="onUploadSuccessClose" />
        <Message ref="error_dialog" />
        <CommonDialog ref="cancel_dialog" @onClose="onCancelClose" />
    </div>
</template>

<script>
import CommonDialog from '@/shared/components/CommonDialog'
import Message from '@/shared/components/Message'
import BoxGeoreferencia from '@/dash/form_boxes/box_georeferencia'
import EditorResponsable from '@/dash/components/EditorResponsable'
import DashCard from '@/shared/components/DashCard'
import MapCard from '@/dash/components/MapCard'
import MapMarkerCard from '@/dash/components/MapMarkerCard'
import DatePickerText from '@/shared/components/DatePickerText'
import PersonaListItem from '@/dash/components/PersonaListItem'
import AccessMessage from '@/dash/components/AccessMessage'
import Selector from '@/dash/components/Selector'
import moment from 'moment'
import raw_estados from '@/assets/estados'
import { axiosHelper, messages, valid_required } from '@/helpers'

export default {
    components: {
        Message,
        DashCard,
        DatePickerText,
        CommonDialog,
        Selector,
        MapCard,
        MapMarkerCard,
        PersonaListItem,
        BoxGeoreferencia,
        EditorResponsable,
        AccessMessage
    },
    computed: {
        editMode() {
            return this.$route.meta.editMode;
        },
        areas() {
            return this.editor_data.areas || [];
        },
        gerencias() {
            return this.editor_data.gerencias || [];
        },
        empresas() {
            return this.editor_data.empresas || [];
        },
        personas() {
            return this.editor_data.personas || [];
        },
        estados() {
            return raw_estados['sia'];
        }
    },
    data: () => ({
        disable_geoeditor: false,
        rules: {
            required: value => valid_required(value)
        },
        capas: [],
        editor_data: {},
        sia: {
            fecha_solicitud: moment().format("YYYY-MM-DD"), 
            fecha_cierre: moment().format("YYYY-MM-DD"),
            estado: 1,
            is_georeferenciado: false
        }
    }),
    mounted() {        
        if(!this.$parent.$parent.get_permission('edit')) return;

        let text = "";
        if(this.editMode) {
            text = "Editar Sol. de Intervención de Área";
            axiosHelper.get('sias/' + this.$route.params.id + '/editar')
                .then(response => {
                    this.editor_data = response.data;
                    this.sia = this.editor_data.sia;
                })
            
        } else {
            text = "Añadir Sol. de Intervención de Área";
            axiosHelper.get('sias/crear')
                .then(response => {
                    this.editor_data = response.data;
                })

            if(!!this.$route.params.geo) {
                this.sia.georeferencia = { data: this.$route.params.geo.features[0].geometry };
                this.disable_geoeditor = true;
            }
        }

        this.$emit("title", { text: text, icon: "edit" });
    },
    methods: {
        askSubir(e) {
            e.preventDefault();

            if(!this.$refs.editor.validate()) {
                this.$refs.error_dialog.show(messages.titles["error_upload_editor"], messages.texts["error_check_fields"], "error");
                return;
            }

            this.sia.georeferencia = this.$refs.geo.get_data();
            if(!!this.sia.responsable_cierre.jefe) {
                this.sia.responsable_cierre.jefe = this.sia.responsable_cierre.jefe.id;
            }

            if(this.editMode) {
                axiosHelper.put('sias/' + this.$route.params.id + '/', this.sia)
                .then(response => this.success())
                .catch(error => this.error());
            } else {
                axiosHelper.post('sias/', this.sia)
                .then(response => this.success())
                .catch(error => this.error());
            }
        },
        askCancelar(e) {
            e.preventDefault();
            this.$refs.cancel_dialog.showDialog(
                messages.titles[this.editMode ? "question_cancel_edit" : "question_cancel_new"], 
                messages.texts[this.editMode ? "question_cancel_edit" : "question_cancel_new"], "yes_no");
                
        },
        onCancelClose(e) {
            if(e == "yes") {
                this.$router.back();
            }
        },
        onUploadSuccessClose(e) {
            this.$router.back();
        },
        success() {
            this.$refs.success_dialog.show(messages.titles["success_generico"], messages.texts[this.editMode ? "success_upload_edit" : "success_upload_new"], "success");
        },
        error() {
            this.$refs.error_dialog.show(messages.titles["error_generico"], messages.texts["error_upload"], "error");
        }
    }
}
</script>